import React, { createContext } from 'react'
import { useLocalStorage } from '../hooks/useLocalStorage';

export const ThemeContext = createContext('dark');
export const setThemeContext = createContext();

export default ({ children }) => {
  const [theme, setTheme] = useLocalStorage('theme', 'dark');
  return (
    <ThemeContext.Provider value={theme}>
      <setThemeContext.Provider value={setTheme}>
        {children}
      </setThemeContext.Provider>
    </ThemeContext.Provider>
  )
}
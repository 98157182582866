import React, { createContext, useState } from 'react';

export const LocationContext = createContext('/');
export const SetLocationContext = createContext(() => '/');

export default ({ children }) => {
  const [location, setLocation] = useState('/');
  return (
    <LocationContext.Provider value={location}>
      <SetLocationContext.Provider value={setLocation}>
        {children}
      </SetLocationContext.Provider>
    </LocationContext.Provider>
  )
}
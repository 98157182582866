import React from 'react'
import ThemeProvider from './src/providers/ThemeProvider';
import LocationProvider from './src/providers/LocationProvider';
import ToggleProvider from './src/providers/ToggleProvider';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <ToggleProvider>
      <LocationProvider>
        {element}
      </LocationProvider>
    </ToggleProvider>
  </ThemeProvider>
)

export const onServiceWorkerUpdateReady = () => {
  const language = window.navigator.userLanguage || window.navigator.language;

  if (language === 'en') {
    const answer = window.confirm(
      `This application has been updated. ` +
        `Reload to display the latest version?`
    )
    if (answer === true) {
      window.location.reload()
    } 
  } else if (language === 'es') {
    const answer = window.confirm(
      `This application has been updated. ` +
        `Reload to display the latest version?`
    )
    if (answer === true) {
      window.location.reload()
    } 
  }
}
import React, { createContext, useState } from 'react';

export const ToggleContext = createContext(false);
export const SetToggleContext = createContext(() => false);

export default ({ children }) => {
  const [element, setElementToggle] = useState(false);
  return (
    <ToggleContext.Provider value={element}>
      <SetToggleContext.Provider value={setElementToggle}>
        {children}
      </SetToggleContext.Provider>
    </ToggleContext.Provider>
  )
}